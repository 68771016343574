import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Kaws.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import KawsPhoto1 from "../../../../res/Photos site/Kaws/kaws graff.jpg"
import KawsPhoto2 from "../../../../res/Photos site/Kaws/kaws.jpeg"
import KawsPhoto3 from "../../../../res/Photos site/Kaws/portrait_kaws.jpg"
import KawsPhoto4 from "../../../../res/Photos site/Kaws/2.jpg"
import KawsPhoto5 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kaws - chum, noir.jpg"
import KawsPhoto6 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kaws - chum, orange.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/kaws/portrait.jpeg';
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Kaws",
  name: "Kaws",
  description:
  "Brian Donnelly, aka KAWS, is an artist and designer born in 1974 in the United States who lives and works in Brooklyn. Known primarily for his toys and paintings, the artist has developed his own style inspired by Pop Art and popular culture. After graduating from the School of Visual Arts in New York, he briefly worked at Disney as an animator for the animated series 101 Dalmatians, Daria and Doug. At the same time, KAWS began a career as a graffiti artist. In the early 1990s, the artist hijacked the advertising posters of major consumer brands to replace the faces of the models by symbolic skulls marked with his iconic cross in place of the eyes. He thus intervenes in the streets of New York, London, Tokyo and Paris. In the late 1990s, he began creating exclusive vinyl toys in collaboration with the Japanese company Medicom Toy. Like the masters of American Pop Art, by reworking cartoon characters that embody the collective imagination, such as the Simpsons, Mickey, Snoopy, SpongeBob, Kaws aims to make his art accessible to all. His works can reach monumental dimensions, like his latest 33-meter inflatable sculpture, installed in Taipei, Taiwan. The artist is now a key figure on the contemporary scene and enjoys international recognition. His works are now part of major institutional collections: the High Museum of Art in Atlanta, the Modern Art Museum of Fort Worth, the Brooklyn Museum and the Museum of Contemporary Art in San Diego.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Kaws.",
  photos: [
    { src: KawsPhoto1, name: "Kaws" },
    { src: KawsPhoto4, name: "Kaws" },
    { src: KawsPhoto3, name: "Kaws" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: KawsPhoto5, name: "Kaws", artist:"Kaws\n(Brian Donnelly dit, né en 1974)", title:"Chum, 2002", desc1: "Sculpture en PVC coloré.", desc2 :"Tinted PVC sculpture.", desc3: "34,2 x 21 x 10,3 cm", desc4:"13 7/16 x 8 1/4 x 4 1/16 in.", desc5:"Titré, signé et daté sous le pied gauche : \":CHUM..© KAWS .. 02,212/500\"\nContresigné par la main de l'artiste sous le pied droit \"KAWS .. 03\"\nNuméroté sous le pied droit: \"500 / 212\"\nEdition à 500, figurine n° 212.\nAvec son emballage d'origine.", desc6:"Titles, signed and dated under the left foot : \":CHUM..© KAWS .. 02, 212/500\"\nHand signed by the artist under the right foot \"KAWS .. 03\"\nNumbered under the right foot: \"500 / 212\"\nEdition of 500, figurine n° 212.\nIncludes original packaging.", desc7: "- Collection particulière." },
    { src: KawsPhoto6, name: "Kaws", artist:"Kaws\n(Brian Donnelly dit, né en 1974)", title:"Chum, 2002", desc1: "Sculpture en PVC coloré.", desc2 :"Tinted PVC sculpture.", desc3: "34,2 x 21 x 10,3 cm", desc4:"13 7/16 x 8 1/4 x 4 1/16 in.", desc5:"Titré, signé et daté sous le pied gauche : \":CHUM..© KAWS .. 02\"\nNuméroté sous le pied droit: \"500 / 066\"\nEdition à 500, figurine n° 066.\nAvec son emballage d'origine.", desc6:"Titles, signed and dated under the left foot : \":CHUM..© KAWS .. 02\"\nNumbered under the right foot: \"500 / 066\"\nEdition of 500, figurine n° 066.\nIncludes original packaging.", desc7: "- Collection particulière." },
  ],
};


const DondiWhite = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>KAWS <h2 style={{paddingLeft: "16px"}}>(Brian Donnelly - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1974</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- BFA School of Visual Arts, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Kaws prints, High Museum of art, Atlanta, Georgia, USA.</p>
          <p>- ONLINE: Acute art presents, Kaws: New Fiction, Serpentine Gallery, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Kaws: Spoke To Soon, Skartedt Gallery, NY, Upper east side, New York, USA.</p>
          <p>- Kaws: What Party, Brooklyn Museum of Art, Brooklin, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Kaws: Playtime, NGV international, Melbourne, Australia.</p>
          <p>- Kaws: Companionship in the age of loneliness, NGV international, Melbourne, Australia.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Kaws: Blackout, Skarstedt Gallery, London, UK.</p>
          <p>- Kaws: Alone Again, Mocad Museum of Contemporary Art Detroit, Detroit, Michigan, USA.</p>
          <p>- KAWS: Blame Game, Galerie Boisséree, Cologne, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- GONE, Skarstedt, New York, USA.</p>
          <p>- KAWS: HOLIDAY, Seokchon Lake, Seoul, South Korea.</p>
          <p>- KAWS, Perrotin Gallery, Tokyo, Japan.</p>
          <p>- KAWS, Perrotin Gallery, Hong Kong.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- WHERE THE END STARTS, Yuz Museum, Shanghai, China.</p>
          <p>- KAWS, Contemporary Art Museum St. Louis, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- WHERE THE END STARTS, Modern Art Museum of Fort Worth, Fort Worth, USA.</p>
          <p>- KAWS: BFF, Central Embassy, Bangkok, Thailand</p>
          <p> - KAWS, Perrotin Gallery, Seoul, South Korea</p>
          <p>- KAWS, Yorkshire Sculpture Park, West Yorkshire, UK</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Permanent Vacation II, Eden Rock Gallery, St, Barts</p>
          <p>- ALONG THE WAY, Brooklyn Museum, Brooklyn, NY</p>
          <p>- Newcomb Art Gallery at Woldenburg Art Center, Tulane University, New Orleands, LA</p>
          <p>- CLEAN SLATE, LA NAVE, Las Salinas, Ibiza, Spain</p>
          <p>- CLEAN SLATE, Shanghai Times Square, Shanghai, China</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- MAN’S BEST FRIEND, Honor Fraser, Los Angeles, USA</p>
          <p>- Permanent Vacation, Eden Rock Gallery, St. Barths</p>
          <p>- CLEAN SLATE, Harbour City, Hong Kong, China</p>
          <p>- FINAL DAYS, Centro de Arte Contemporaneo, Malaga, Spain</p>
          <p>- PLAY YOUR PART, Galeria Javier Lopez, Madrid, Spain</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- PASS THE BLAME, Galerie Perrotin New York, USA</p>
          <p>- Mary Boone Gallery in cooperation with Galerie Perrotin, New York, USA</p>
          <p>- Ups and Downs, The Nerman Museum of Contemporary Art, Overland Park, USA</p>
          <p>- KAWS @ PAFA, inside PAFA’s Historic Landmark Building</p>
          <p>- Sculpture Plinth Exhibition Program: KAWS, Pennsylvania Academy of Fine Arts, Philadelphia, PA.</p>
          <p>- KAWS GISWIL, More Gallery, Giswil, Switzerland</p>
          <p>- Ohhh...,Kaikai Kiki Gallery, Tokyo, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Imaginary Friends, Galerie Perrotin, Paris, France</p>
          <p>- The Nature of Need, Galerie Perrotin, Hong Kong</p>
          <p>- KAWS: Down Time, High Museum of Art, Atlanta, GA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- KAWS: Companion, High Museum of Art, Atlanta, GA</p>
          <p>- FOCUS: KAWS, Modern Art Museum of Fort Worth, Fort Worth, TX</p>
          <p>- Hold The Line, Honor Fraser Gallery, Los Angeles, CA</p>
          <p>- Passing Through, The Standard, New York, NY</p>
          <p>- Passing Through, Aldrich Museum of Contemporary Art, Ridgefield, CT</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Passing Through, Harbour City, Hong Kong, China</p>
          <p>- Pay the Debt to Nature, Galerie Perrotin, Paris, France</p>
          <p>- KAWS, Aldrich Museum of Contemporary Art, Ridgefield, CT</p>
          <p>- Galeria Javier Lopez, Madrid, Spain</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- The Long Way Home, Honor Fraser, Los Angeles, CA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Gering & López Gallery, New York, NY</p>
          <p>- Galerie Emmanuel Perrotin, Miami, FL</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Original Fake, BAPE Gallery, Tokyo, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Elms Lesters Painting Room, London, UK</p>
          <p>- C9, MU Art Foundation, Eindhoven, Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Tokyo First, Parco Gallery, Tokyo, Japan </p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Recent Editions, pace prints, 26TH Street, Chelsea, New York, USA.</p>
          <p>- Austin on paper, West Chelsea Contemporary, Austin, TX, USA.</p>
          <p>- Concrete to canvas, West Chelsea Contemporary, Austin, TX, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Art of Wishes, Charity Auction, Sotheby’s London, London, UK.</p>
          <p>- Black/ White, Skarstedt Gallery, East Hampton, New York, USA.</p>
          <p>- The Looking Glass, The Shed Chelsea, New York, USA.</p>
          <p>- Spring Contemporary, Maddox Gallery, London, UK.</p>
          <p>- Five, Maddox Gallery, London, UK.</p>
          <p>- ONLINE: I See Faces, Gazelli art house, London,UK.</p>
          <p>- It’s not possible, Today Art Museum, Chaoyang, Benjin, China.</p>
          <p>- Highlights from the permanent collection, Modern Art of Fort Worth, Texas, USA.</p>
          <p>- Mirage: Contemporary art in augmented reality, UCCA, Benjin, China.</p>
          <p>- Winter Contemporary, Maddox Gallery, London, UK.</p>
          <p>- Winter Contemporary, Maddox Gallery, GSTAAD, Switzerland.</p>
          <p>- Summer Contemporary, GSTAAD, Switzerland.</p>
          <p>- Edition Art 2, uJun Art Center, Seoul, South Korea.</p>
          <p>- Prints and multiple, HK art Advisory + Projects, Los Angeles, CA, USA.</p>
          <p>- Icons Vandals, West Chelsea Contemporary, Austin, TX, USA.</p>
          <p>- Mr. Brainwash and Kitzbuehel Winter Crossover, Galerie Frank Fluegel, Kitzbuehel, Austria.</p>
          <p>- Looney Tunes, Corridor contemporary, Tel Aviv, Israel.</p>
          <p>- On The Bright Side, Opera Gallery, Paris, France.</p>
          <p>- This is now, West Chelsea Contemporary, Austin, TX, USA.</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Prints in the 21st century, AUgen Gallery, (Desoto Building), Portland, USA.</p>
          <p>- XXI, Hofa Gallery, London, UK.</p>
          <p>- ONLINE: Artcels present Hofa Gallery, Los Angeles, California, USA.</p>
          <p>- Matt Black X Gana Art: Reflections, Ganan art center, Seoul, South Korea.</p>
          <p>- Brand x: 40 Years, Pace prints, 26TH Street, Chelsea, New York, USA.</p>
          <p>- Happy! NSU art Museum, Fort Lauderdale, Florida, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Good Grief, Charlie Brown !, Somerset House, London, UK.</p>
          <p>- Summer Show 2019, Zemack Contemporary art, Tel Aviv, Israel.</p>
          <p>- Get out while you can, Maddox Gallery, London, UK.</p>
          <p>- Get out while you can, Maddox Gallery, GSTAAD, Switzerland.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Approaching The Figure, Skarstedt, New York, New York</p>
          <p>- Sunday Morning, Galerist, Beyoglu, Istanbul, Turkey.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Holdings: Selections from MCASD's Collection, Museum of Contemporary Art San Diego, La Jolla, USA</p>
          <p>- Greek Gotham, Dio Horia Gallery, Mykonos, Greece</p>
          <p>- A Material Legacy: The Nancy A. Nasher and David J. Haemisegger Collection of Contemporary Art, Nasher Museum of Art at Duke University, Durham, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Respect, Centro de Arte Contemporáneo, Málaga, Spain</p>
          <p>- ArtZuid 2015, Amsterdam, Netherlands</p>
          <p>- A Shared Space: KAWS, Karl Wirsum, Tomoo Gokita, Newcomb Art Museum, New Orleans, U.S.A</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- If You're Accidentally Not Included, Don't Worry About It., curated by Peter Saul, Zurcher Studio, New York, USA</p>
          <p>- Joyride, Marlborough Chelsea, New York, NY</p>
          <p>- Ok, I'll rename it, Bill Brady KC, Kansas City, MO</p>
          <p>- Knock! Knock!, Galeria Javier Lopez, Madrid, Spain</p>
          <p>- Full House, Garth Greenan Gallery, New York, NY</p>
          <p>- PAINT NEW YORK, GL Strand, Copenhagen, Denmark</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Happy Birthday Galerie Perrotin / 25ans, TRIPOSTAL, Lille, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- White Columns Benefit Exhibition and Auction, White Columns, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- PRETTY ON THE INSIDE, organized by Erik Parker and KAWS, Paul Kasmin Gallery, New York, NY</p>
          <p>- Party Animals, Royal-T, Los Angeles, CA</p>
          <p>- Art in the Streets, Museum of Contemporary Art, Los Angeles, CA</p>
          <p>- FACEMAKER, curated by Katy Grayson, Royal/T, Los Angeles, CA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Mutant Pop, Curated by Joe Grillo, Laura Grant, and Brandon Joyce, Galleri Loyal, Malmo, Sweden</p>
          <p>- It Ain't Fair, OHWOW, Miami, FL</p>
          <p>- The Reflected Gaze - Self Portraiture Today, The Torrance Art Museum, Torrance, CA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Just what is it that make today's painting so different, so appealing, Gering & López Gallery, New York, NY</p>
          <p>- Stages, organized by Lance Armstrong and Nike, Galerie Emannuel Perrotin, Paris, France</p>
          <p>- Alpha Exotica, curated by Dimitrios Antonitsis, Hydra School Projects, Athens, Greece</p>
          <p>- The New Yorkers, V1 Gallery, Copenhagen, Denmark</p>
          <p>- Plastic Culture, Harris Museum and Art Gallery, Preston, UK</p>
          <p>- I Can't Feel My Face, curated by KAWS, Royal-T, Los Angeles, CA </p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Everything Else, Franklin Parrasch Gallery, New York, NY </p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- POPcentric, Gering & López Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Info Babble, Richard & Dolly Maass Gallery, Purchase, NY</p>
          <p>- Ugly Winners, Galerie Du Juor Agnes B., Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Beautiful Losers, curated by Aaron Rose and Christian Strike, Le Tri Postal, Lille, France, Palazzo Dell'Arte, Milan, Italy, USF Contemporary Art Museum, Tampa, FL, Contemporary Museum, Baltimore, MD, Orange County - Museum of Art, Newport Beach, CA, Yerba Buena Arts Center, San Francisco, CA, Contemporary Arts Center, Cincinnati, OH</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- SK8 on the Wall, Parco Gallery, Tokyo, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Session The Bowl, Deitch Projects, New York, NY</p>
          <p>- ALIFE Shop, Deitch Projects, New York, NY</p>
          <p>- Coded Language, City Gallery Chastain, Atlanta, GA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Shortcuts, Nicosia Municipal Arts Centre, Athens, Greece</p>
          <p>- Pierides Museum of Contemporary Art, Athens, Greece</p>
          <p>- New Acquisitions from the Dakis Joannou Collection, Deste Foundation, Athens, Greece</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Brooklyn Museum, New York</p>
          <p>- CAC Malaga, Malaga, Spain</p>
          <p>- City Museum, St. Louis, Missouri</p>
          <p>- Hall Art Foundation, Reading, Vermont</p>
          <p>- High Museum, Atlanta, Georgia</p>
          <p>- Modern Art Museum of Fort Worth, Fort Worth, Texas</p>
          <p>- Museum of Contemporary Art San Diego, California</p>
          <p>- Nerman Museum of Contemporary Art, Overland Park, Kansas</p>
          <p>- Pizzuti Collection, Columbus, Ohio</p>
          <p>- Rosenblum Collection, Paris</p>
          <p>- Zabludowicz Collection, London</p>
          <p id="end">- Marciano Art Foundation, Los Angeles, California</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default DondiWhite;