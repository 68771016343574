// extracted by mini-css-extract-plugin
export var ArtistDescription = "Kaws-module--ArtistDescription--TtVV0";
export var ArtistInfos = "Kaws-module--ArtistInfos--Yzwqv";
export var ButtonWrapper = "Kaws-module--ButtonWrapper --jmVxF";
export var CardWrapper = "Kaws-module--CardWrapper---ncoU";
export var CarrouselWrapper2 = "Kaws-module--CarrouselWrapper2--AsdTy";
export var Citations = "Kaws-module--Citations--1wYlo";
export var DescriptionWrapper = "Kaws-module--DescriptionWrapper--+ZJ-Y";
export var ImageWrapper = "Kaws-module--ImageWrapper--CqKoS";
export var LinkWrapper = "Kaws-module--LinkWrapper--0ad7I";
export var MobileProtrait = "Kaws-module--MobileProtrait--vSeyV";
export var More = "Kaws-module--More--wwQoH";
export var MoreButton = "Kaws-module--MoreButton--TYCv9";
export var NameWrapper = "Kaws-module--NameWrapper--aZqtD";
export var PdpWrapper = "Kaws-module--PdpWrapper--x6v+Z";
export var PhotosWrapper = "Kaws-module--PhotosWrapper--edOKm";
export var ProfilWrapper = "Kaws-module--ProfilWrapper--O809B";
export var TitleWrapper = "Kaws-module--TitleWrapper--qWWao";
export var Wrapper = "Kaws-module--Wrapper--a+BkP";